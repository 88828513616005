import React from 'react';
import { Link, graphql, StaticQuery } from "gatsby";
import DefaultLayout from '../layouts/default';
import FluidImage from '../components/FluidImage.js';
import Breadcrumbs from '../components/Custom/Navigation/Breadcrumbs';

export default ({ location }) => {
//  const dispatch = useDispatch();

//   React.useEffect(() => {

//   });



  return (
    <DefaultLayout location={location}>

        <Breadcrumbs location={location} />

        <div className="container is-layout">
            <section className="section">

                <div className="columns is-tablet is-centered">
                    <div className="column is-8">
                        <h1>A lasting imprint.</h1>

                        <h4>Owner and operator</h4>
                        <p className="is-normal">Network Ventures eGen<br/>
                            Leopoldstraße 24<br/>
                            6020 Innsbruck<br/>
                            Austria
                        </p>
                        <p className="is-normal mt-25">
                            URL: www.ntwk.ventures<br/>
                            Email: <a href="mailto:hi@ntwk.ventures">hi@ntwk.ventures</a>
                        </p>
                        <p className="is-normal mt-25">Bank Account: Raiffeisenkasse<br/>
                        IBAN: AT53 3600 0000 0091 1396<br/>
                        BIC: RZTIAT22XXX</p>
                        <p className="is-normal mt-25">Company structure: Registered cooperative<br />
                        Auditor: Revisionsverband des Raiffeisenverbandes Tirol, Michael-Gaismair-Straße 8, 6020 Innsbruck, Austria</p>
                        <p className="is-normal mt-25">Companies' register: FN&nbsp;513077&nbsp;a<br />
                        UID: ATU75570767</p>
                        <p className="is-normal mt-25">
                            Design and Development: <a href="http://www.transporter.at">Transporter</a>
                        </p>
                        
                        <hr className="my-50"/>

                        <h4 className="mt-25">Field of Activity</h4>
                        <p className="is-normal">Tyrol, Austria based venture builder cooperative</p>
                        
                        <h4 className="mt-25">Principle direction</h4>
                        <p className="is-normal">Information about the cooperative and its projects, member and partner onboarding, project submission</p>
                        
                        <h4 className="mt-25">Disclaimer</h4>
                        <p className="is-normal">We always try to check all information and to communicate information as correct and complete as possible. With regard to the technical properties of the internet, however, we cannot guarantee the correctness, integrity and availability of the information provided, the operation of our / this website and its content. We are not liable for damage caused by the use or non-use of the services and information provided, even if the information is incorrect, incomplete or unavailable.</p>
                        <p className="is-normal mt-25">Any liability for direct, indirect or other damage, regardless of its causes, that you or others may incur as a result of the use or unavailability of the data and information on this website, we must, unless gross negligence can be proven and as far as legally permissible, exclude.</p>
                        <p className="is-normal mt-25">We reserve the right to make changes to the pages, functions and content of this website without prior notice, to temporarily pause or permanently discontinue the service.</p>

                        {/* <nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase my-50">
                            <ul>
                                <li>
                                    <Link to={"/termsofuse"}>
                                        Terms of Use
                                        <span className="icon icon-arrow-right"></span>
                                    </Link>
                                </li>
                            </ul>
                        </nav> */}
                        
                        <h4 className="mt-25">Copyright</h4>
                        <p className="is-normal">The content of this website and the Network Ventures social media presences are protected by copyright. The information is only intended for your personal use. Any further use, in particular storage in databases, reproduction and any form of commercial use as well as disclosure to third parties – also in parts or in a revised form – is prohibited without Network Ventures' written consent.</p>
                        
                        <h4 className="mt-25">Links</h4>
                        <p className="is-normal">If you want to set links to ntwk.ventures as well as to our social media presences, you are very welcome. Thanks! However, we do not agree to the integration of individual pages of our offer into third-party websites.</p>
                        
                        <h4 className="mt-25">Dispute Resolution</h4>
                        <p className="is-normal">Platform of the EU Commission for online dispute resolution: <a href="https://ec.europa.eu/odr" title="Platform of the EU Commission for online dispute resolution">https://ec.europa.eu/odr</a></p>
                        <p className="is-normal mt-25">We are neither obliged nor willing to participate in a dispute settlement procedure before a consumer arbitration board.</p>
                        
                        <h4 className="mt-25">Gender Notice</h4>
                        <p className="is-normal">For reasons of readability, we use the male form. Corresponding terms apply in the sense of equal treatment to all genders. The shortened form of language is only for editorial reasons and does not include any evaluation.</p>
                    </div>
                </div>

            </section>
        </div>
        
    </DefaultLayout>
  );
};

